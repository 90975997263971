import React, {useEffect, useState} from "react";
import Button from "@prism/button";
import Icon from "@prism/icon";
import {connect} from "react-redux";
import {isNativeApp} from "../../../../utils/utils";
import {deleteDamageImage} from "../../../../actions/damageImageActions";
import Confirm from "../../../common/Confirm";
import {UPDATED} from "../../../../utils/constants";

const capturePhoto = (props) => {
    const {damageKey, auctionCode, category, title, workOrderNumber, vin} = props;

    if (isNativeApp()) {
        console.log(
            "capture photo with",
            auctionCode,
            workOrderNumber,
            damageKey,
            category
        );
        window.NativeApp.capturePhoto(
            workOrderNumber,
            vin,
            damageKey,
            category,
            title
        );
    } else {
        console.log(
            "camera not available in web view",
            auctionCode,
            workOrderNumber,
            damageKey
        );
    }
};

const TakePhotoButton = (props) => {
    const {auctionCode, workOrderNumber} = props.workOrderInfo;
    const {vin} = props.designatedDescription
    const {damageKey, damageImage, damageImages} = props;
    const [hasLocalImage, setHasLocalImage] = useState(false);

    const handleRetakePhotoClick = (captureRequest) => {
        if (isNativeApp()) {
            props.deleteDamageImage(damageKey);
            capturePhoto(captureRequest);
            props.toggle && props.toggle();
        }
    };

    useEffect(() => setHasLocalImage(damageImage && damageImage.status === UPDATED), [damageImage, damageImages]);
    return props.hasPhoto ? (

        hasLocalImage ?
            <Confirm
                title="Retake Photo"
                description="Selecting 'Retake' will permanently delete the current image. Are you sure you want to proceed?"
                submitDescription="Retake"
                cancelDescription="Cancel"
            >
                {(confirm) => (
                    <Button
                        className="w-100 retake-photo"
                        color="secondary"
                        disabled={props.disabled}
                        id={"retake-photo-" + props.damageKey}
                        onClick={confirm(() => {
                            handleRetakePhotoClick({
                                auctionCode: auctionCode,
                                workOrderNumber: workOrderNumber,
                                damageKey: props.damageKey,
                                vin: vin,
                                category: "DAMAGE",
                                title: props.title
                            })
                        })}
                    >
                        <Icon glyph="camera" className="large-button-icon mr-2"/>
                        RETAKE PHOTO
                    </Button>
                )}
            </Confirm> :
            <Button
                className="w-100 retake-photo"
                color="secondary"
                disabled={props.disabled}
                id={"retake-photo-" + props.damageKey}
                onClick={() =>
                    capturePhoto({
                        auctionCode: auctionCode,
                        workOrderNumber: workOrderNumber,
                        damageKey: props.damageKey,
                        vin: vin,
                        category: "DAMAGE",
                        title: props.title
                    })
                }
            >
                <Icon glyph="camera" className="large-button-icon mr-2"/>
                RETAKE PHOTO
            </Button>
    ) : (
        <Button
            className="w-100 take-photo"
            color="secondary"
            disabled={props.disabled}
            onClick={() =>
                capturePhoto({
                    auctionCode: auctionCode,
                    workOrderNumber: workOrderNumber,
                    damageKey: props.damageKey,
                    vin: vin,
                    category: "DAMAGE",
                    title: props.title
                })
            }
        >
            <Icon glyph="camera" className="large-button-icon"/>
        </Button>
    );
};

function mapStateToProps({workOrderInfo, designatedDescription, damageImages}) {
    return {workOrderInfo, designatedDescription, damageImages};
}

const matchDispatchToProps = {
    deleteDamageImage
};

export default connect(mapStateToProps, matchDispatchToProps)(TakePhotoButton);
