import {
    ADD_ANNOUNCEMENT_FOR_DAMAGE,
    CLEAR_INCOMPLETE_DAMAGE,
    CLEAR_SELECTED_FLAT_CAR_PANEL,
    DELETE_ANNOUNCEMENT_FOR_STRUCTURAL_DAMAGE,
    FLOOD_DAMAGE_SELECTED,
    HANDLE_KEEP_PHOTO_RESPONSE,
    HIDE_AUDIT_EXISTS_MODAL,
    HIDE_IMAGE_UPLOAD_MODAL,
    HIDE_BUTTON_TRAY,
    HIDE_CANCEL_BUTTON_FOR_LOAD,
    HIDE_CLOSE_INSPECTION_CONFIRMATION,
    HIDE_CONTINUE_INCOMPLETE_WORK_ORDER_MODAL,
    HIDE_DAMAGE_SUCCESS_MESSAGE,
    HIDE_DELETE_INCOMPLETE_INSPECTION_MODAL,
    HIDE_LOCKED_RECORD_MODAL,
    HIDE_LOGOUT_MODAL,
    HIDE_REDECODE_MODAL,
    HIDE_TRIM_FINDER,
    HIDE_WORK_ORDER_ERROR_MODAL,
    LOAD_WORK_ORDER_SUCCESS,
    RE_DECODE_VIN,
    SAVE_INCOMPLETE_DAMAGE,
    SET_CLEAR_PANEL_OFF,
    SET_CLEAR_PANEL_ON,
    SET_CONDITION_FROM_REPURPOSED,
    SET_USE_ODOMETER_READING_FROM_UNIT,
    SET_SELECTED_FLAT_CAR_PANEL,
    SHOW_ADD_DAMAGE_PANEL,
    SHOW_ADD_DAMAGE_VIEW,
    SHOW_ADD_EDIT_DAMAGE,
    SHOW_AUDIT_EXISTS_MODAL,
    SHOW_BUTTON_TRAY,
    SHOW_CANCEL_BUTTON_FOR_LOAD,
    SHOW_CLOSE_INSPECTION_CONFIRMATION,
    SHOW_CONTINUE_INCOMPLETE_WORK_ORDER_MODAL,
    SHOW_CURRENT_FLAT_CAR_VIEW,
    SHOW_DAMAGE_LIST,
    SHOW_DAMAGE_SUCCESS_MESSAGE,
    SHOW_DAMAGE_SUMMARY_VIEW,
    SHOW_DELETE_INCOMPLETE_INSPECTION_MODAL,
    SHOW_EXTERIOR_FLAT_CAR_VIEW,
    SHOW_INTERIOR_FLAT_CAR_VIEW,
    SHOW_LOCKED_RECORD_MODAL,
    SHOW_LOGOUT_MODAL,
    SHOW_MECHANICAL_FLAT_CAR_VIEW,
    SHOW_REDECODE_MODAL,
    SHOW_STRUCTURAL_FLAT_CAR_VIEW,
    SHOW_SUGGESTED_DAMAGES_PANEL,
    SHOW_TRIM_FINDER,
    SHOW_WORK_ORDER_ERROR_MODAL,
    STRUCTURAL_ALTERATION_SELECTED,
    STRUCTURAL_DAMAGE_SELECTED,
    TOGGLE_BRAKE_FLUID_SERVICE_SELECTED,
    TOGGLE_DAMAGE_MODAL,
    TOGGLE_ENGINE_OIL_SERVICE_SELECTED,
    UNLOAD_WORK_ORDER,
    UPDATE_BODY_STYLE,
    UPDATE_CYLINDER_COUNT,
    UPDATE_DAMAGE_IMAGE,
    UPDATE_MAKE,
    UPDATE_MODEL,
    UPDATE_ODOMETER_CONFIRMED,
    UPDATE_SERVICE_REQUIRED,
    UPDATE_VEHICLE_IS_SOLD
} from "../actions/dispatchTypes";
import update from "immutability-helper";
import {isAuditMode, uuid} from "../utils/utils";
import {
    APPROVAL_RECORD_EXISTS,
    BRAKE_FLUID,
    COPY_USER,
    ENGINE_OIL,
    VEHICLE_IS_SOLD
} from '../utils/constants'
import {SERVICE_REQUIRED_DAMAGE} from '../utils/constantsDamages';
import {STRUCTURAL_DAMAGE_ITEMS} from "../utils/structuralDamageItems";
import {ENABLE_ADD_DAMAGES} from "../config/env";

export default function navigationReducer(state = defaultView(), action) {

    switch (action.type) {
        case SHOW_DAMAGE_SUCCESS_MESSAGE:
            return {...state, showDamageSuccessMessage: true};
        case LOAD_WORK_ORDER_SUCCESS:
            return initWorkOrderView(state, action.payload);
        case SET_CONDITION_FROM_REPURPOSED:
            return initWorkOrderView(state, action.payload, true);
        case HIDE_DAMAGE_SUCCESS_MESSAGE:
            return {...state, showDamageSuccessMessage: false};
        case HIDE_WORK_ORDER_ERROR_MODAL:
            return {...state, errorFetchingWorkOrder: false};
        case SHOW_AUDIT_EXISTS_MODAL:
            return {...state, shouldShowAuditExistsModal: true};
        case HIDE_AUDIT_EXISTS_MODAL:
            return {...state, shouldShowAuditExistsModal: false};
        case HIDE_IMAGE_UPLOAD_MODAL:
            return {...state, prevImageUploadFailed: false}
        case SHOW_ADD_EDIT_DAMAGE:
            return showAddEditDamage(state, action.currentDamage);
        case SHOW_DAMAGE_LIST:
            return showDamageListView(state);
        case SHOW_EXTERIOR_FLAT_CAR_VIEW:
            return {...state, flatCarView: "exterior"};
        case SHOW_INTERIOR_FLAT_CAR_VIEW:
            return {...state, flatCarView: "interior"};
        case SHOW_LOGOUT_MODAL:
            return {...state, shouldShowLogoutModal: true};
        case HIDE_LOGOUT_MODAL:
            return {...state, shouldShowLogoutModal: false};
        case SHOW_CURRENT_FLAT_CAR_VIEW:
            return {...state, flatCarView: action.currentFlatCarView}
        case SHOW_MECHANICAL_FLAT_CAR_VIEW:
            return {...state, flatCarView: "mechanical"};
        case SHOW_STRUCTURAL_FLAT_CAR_VIEW:
            return {...state, flatCarView: "structural"};
        case SHOW_WORK_ORDER_ERROR_MODAL:
            return showWorkOrderErrorModal(state, action.message);
        case SET_SELECTED_FLAT_CAR_PANEL:
            return {...state, selectedFlatCarPanel: action.panelId, clearSelectedPanel: true, displaySuggestedDamages: true};
        case CLEAR_SELECTED_FLAT_CAR_PANEL:
            return {...state, selectedFlatCarPanel: null};
        case SHOW_BUTTON_TRAY:
            return {...state, showButtonTray: false};
        case HIDE_BUTTON_TRAY:
            return {...state, showButtonTray: false};
        case TOGGLE_DAMAGE_MODAL:
            return toggleDamageModalByType(state, action.modalType);
        case FLOOD_DAMAGE_SELECTED:
            return {...state, floodDamageSelected: true};
        case STRUCTURAL_DAMAGE_SELECTED:
            return {...state, structuralDamageSelected: true};
        case DELETE_ANNOUNCEMENT_FOR_STRUCTURAL_DAMAGE:
            return {...state, structuralDamageSelected: false};
        case STRUCTURAL_ALTERATION_SELECTED:
            return {...state, structuralAlterationSelected: true};
        case TOGGLE_ENGINE_OIL_SERVICE_SELECTED:
            return {...state, engineOilServiceSelected: !state.engineOilServiceSelected};
        case TOGGLE_BRAKE_FLUID_SERVICE_SELECTED:
            return {...state, brakeFluidServiceSelected: !state.brakeFluidServiceSelected};
        case UPDATE_SERVICE_REQUIRED:
            return {...state, serviceRequiredSelected: true};
        case SHOW_CLOSE_INSPECTION_CONFIRMATION:
            return {...state, showCloseInspectionConfirm: true};
        case HIDE_CLOSE_INSPECTION_CONFIRMATION:
            return {...state, showCloseInspectionConfirm: false};
        case HANDLE_KEEP_PHOTO_RESPONSE:
        case UPDATE_DAMAGE_IMAGE:
            return {...state, damageImageChecksum: uuid()};
        case SHOW_CANCEL_BUTTON_FOR_LOAD:
            return {...state, showCancelButtonForLoad: true};
        case HIDE_CANCEL_BUTTON_FOR_LOAD:
            return {...state, showCancelButtonForLoad: false};
        case UPDATE_VEHICLE_IS_SOLD:
            return {...state, vehicleIsSold: action.vehicleIsSold};
        case UNLOAD_WORK_ORDER:
            return {...state, workOrderOpen: false, cylinderCountId: null, vehicleIsSold: false, hasAudit: false};
        case HIDE_REDECODE_MODAL:
            return {...state, shouldShowRedecodeModal: false};
        case SHOW_REDECODE_MODAL:
            return {...state, shouldShowRedecodeModal: true};
        case RE_DECODE_VIN:
            return {...state, isRedecode: true}
        case SHOW_CONTINUE_INCOMPLETE_WORK_ORDER_MODAL:
            return {...state, showContinueIncompleteWorkOrderModal: true}
        case HIDE_CONTINUE_INCOMPLETE_WORK_ORDER_MODAL:
            return {...state, showContinueIncompleteWorkOrderModal: false}
        case SHOW_DELETE_INCOMPLETE_INSPECTION_MODAL:
            return {...state, showDeleteIncompleteInspectionModal: true, currentWorkOrder: action.currentWorkOrder}
        case HIDE_DELETE_INCOMPLETE_INSPECTION_MODAL:
            return {...state, showDeleteIncompleteInspectionModal: false}
        case UPDATE_BODY_STYLE:
            return updateVehicleTypeId(state, action.payload);
        case UPDATE_MAKE:
            return updateMakeId(state, action.payload);
        case UPDATE_CYLINDER_COUNT:
            return updateCylinderCountId(state, action.payload);
        case UPDATE_MODEL:
            return updateModelId(state, action.payload);
        case SHOW_DAMAGE_SUMMARY_VIEW:
            return {...state, damageListView: "summary"};
        case SHOW_ADD_DAMAGE_VIEW:
            return showAddDamageView(state);
        case SHOW_ADD_DAMAGE_PANEL:
            return showAddDamagePanel(state, action.currentDamage);
        case SHOW_SUGGESTED_DAMAGES_PANEL:
            return {...state, displaySuggestedDamages: true, currentDamage: null};
        case SET_CLEAR_PANEL_ON:
            return {...state, clearSelectedPanel: true};
        case SET_CLEAR_PANEL_OFF:
            return {...state, clearSelectedPanel: false};
        case SHOW_LOCKED_RECORD_MODAL:
            return {...state, showLockedRecordModal: true};
        case HIDE_LOCKED_RECORD_MODAL:
            return {...state, showLockedRecordModal: false};
        case ADD_ANNOUNCEMENT_FOR_DAMAGE:
            return setStructuralDamageSelected(state, action.payload)
        case SHOW_TRIM_FINDER:
            return {...state, showTrimFinder: true};
        case HIDE_TRIM_FINDER:
            return {...state, showTrimFinder: false};
        case UPDATE_ODOMETER_CONFIRMED:
            return {...state, odometerConfirmed: action.payload};
        case SET_USE_ODOMETER_READING_FROM_UNIT:
            return {...state, useOdometerReadingFromUnit: action.payload};
        case SAVE_INCOMPLETE_DAMAGE:
            return {...state, incompleteDamage: action.payload};
        case CLEAR_INCOMPLETE_DAMAGE:
            return {...state, incompleteDamage: null};
        default:
            return state;
    }
}

function showAddEditDamage(state, damage = {}) {
    return {
        ...state,
        damagePanelView: 'damageEditView',
        currentDamage: damage,
        flatCarView: "exterior",
        suggestedDamage: false,
        displaySuggestedDamages: false
    }
}

function showAddDamageView(state, damage = {}) {
    return {
        ...state,
        damagePanelView: 'damageListView',
        damageListView: "addDamage",
        currentDamage: damage,
        suggestedDamage: false
    }
}

function showAddDamagePanel(state, damage = {}) {
    return {
        ...state,
        currentDamage: damage,
        displaySuggestedDamages: false
    }
}

function showWorkOrderErrorModal(state, message = {}) {
    if (message.errorType === VEHICLE_IS_SOLD) {
        return {
            ...state,
            vehicleIsSold: true,
            showContinueButtonOnModal: true,
            errorFetchingWorkOrder: true,
            vehicleErrorMsg: message.error,
            vehicleErrorType: message.errorType,
            showCancelButtonForLoad: false
        }
    } else if (message.errorType === APPROVAL_RECORD_EXISTS) {
        return {
            ...state,
            approvalExists: true,
            showContinueButtonOnModal: true,
            errorFetchingWorkOrder: true,
            vehicleErrorMsg: message.error,
            vehicleErrorType: message.errorType,
            showCancelButtonForLoad: false
        }
    } else {
        return {
            ...state,
            errorFetchingWorkOrder: true,
            vehicleErrorMsg: message.error,
            vehicleErrorType: message.errorType,
            showCancelButtonForLoad: false
        }
    }
}

function showDamageListView(state) {
    return {...state, damagePanelView: 'damageListView',  suggestedDamage: false}
}

function defaultView() {
    return {
        damagePanelView: 'damageListView',
        vehicleError: false,
        vehicleErrorMsg: "",
        errorFetchingWorkOrder: false,
        showCancelButtonForLoad: false,
        showButtonTray: false,
        showDamageSuccessMessage: false,
        workOrderOpen: false,
        displaySuggestedDamages: true,
        incompleteDamage: null,
        clearSelectedPanel: true,
        damageListView: ENABLE_ADD_DAMAGES === 'true' ? "addDamage" : "suggested"
    }
}

function getEngineOilServiceSelected(payload) {
    let engineOilServiceSelected = false;
    if (payload.condition.damages) {
        const serviceRequiredDamage = payload.condition.damages.find(damage => damage.item === SERVICE_REQUIRED_DAMAGE.item && !!damage.notes);
        if (serviceRequiredDamage && serviceRequiredDamage.notes.toLowerCase().includes(ENGINE_OIL.toLowerCase())) {
            engineOilServiceSelected = true;
        }
    }
    return engineOilServiceSelected;
}

function getBrakeFluidServiceSelected(payload) {
    let brakeFluidServiceSelected = false;
    if (payload.condition.damages) {
        const serviceRequiredDamage = payload.condition.damages.find(damage => damage.item === SERVICE_REQUIRED_DAMAGE.item && !!damage.notes);
        if (serviceRequiredDamage && serviceRequiredDamage.notes.toLowerCase().includes(BRAKE_FLUID.toLowerCase())) {
            brakeFluidServiceSelected = true;
        }
    }
    return brakeFluidServiceSelected;
}

function initWorkOrderView(state, payload, isRepurposed = false) {
    let newState = initialWorkOrderView(state, payload, isRepurposed);
    if (!payload.condition) {
        return newState
    }
    if (payload.condition?.href || isRepurposed) {
        newState = update(newState, {
            $merge: {
                floodDamageSelected: true,
                structuralDamageSelected: true,
                structuralAlterationSelected: true,
                engineOilServiceSelected: getEngineOilServiceSelected(payload),
                brakeFluidServiceSelected: getBrakeFluidServiceSelected(payload),
                serviceRequiredSelected: true
            }
        })
    }
    return newState;
}

function initialWorkOrderView(state, payload, isRepurposed) {
    return {
        ...state,
        damagePanelView: 'damageListView',
        incompleteDamage: null,
        vehicleErrorType: null,
        vehicleError: false,
        vehicleErrorMsg: "",
        errorFetchingWorkOrder: false,
        showCancelButtonForLoad: false,
        floodDamageSelected: null,
        structuralDamageSelected: null,
        structuralAlterationSelected: null,
        currentDamage: null,
        flatCarView: null,
        selectedFlatCarPanel: null,
        engineOilServiceSelected: null,
        brakeFluidServiceSelected: null,
        serviceRequiredSelected: null,
        inspectionCompleted: payload.inspectionCompleted,
        workOrderOpen: true,
        isRedecode: false,
        approvalExists: false,
        showLockDialog: false,
        odometerConfirmed: isAuditMode(),
        hasAudit: payload.hasAudit,
        shouldShowAuditExistsModal: isAuditMode() && !!payload.hasAudit,
        useOdometerReadingFromUnit: shouldUseUnitReading(payload, isRepurposed),
        prevImageUploadFailed: payload.prevImageUploadFailed
    }
}

function shouldUseUnitReading(payload, isRepurposed) {
    return !(!payload.condition || !payload.condition.href || payload.condition.writer === COPY_USER || isRepurposed);
}

function toggleDamageModalByType(state, modalType) {
    return {...state, [modalType]: !state[modalType]}
}

function updateVehicleTypeId(state, payload) {
    return {
        ...state,
        vehicleTypeId: payload.vehicleTypeId,
        vehicleSubTypeId: payload.vehicleSubTypeId
    }
}

function setStructuralDamageSelected(state, payload) {
    const longDamageKey = `${payload.itemCode}-${payload.subItemCode}-${payload.damageCode}-${payload.severityCode}`;
    let isStructuralDamage;
    if (STRUCTURAL_DAMAGE_ITEMS.includes(longDamageKey)) {
        isStructuralDamage = true;
    }
    if (isStructuralDamage) {
        return {
            ...state,
            structuralDamageSelected: true
        }
    } else {
        return {
            ...state
        }
    }
}

function updateMakeId(state, payload) {
    return {
        ...state,
        makeId: payload.id
    }
}

function updateCylinderCountId(state, payload) {
    return {
        ...state,
        cylinderCountId: payload.id
    }
}

function updateModelId(state, payload) {
    return {
        ...state,
        modelId: payload.id
    }
}