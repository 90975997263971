import PropTypes from "prop-types";
import DeleteDamageImageButton from "./DeleteDamageImageButton";
import TakePhotoButton from "./TakePhotoButton";
import RequiredPhotoMessage from "./RequiredPhotoMessage";
import {connect} from "react-redux";

const propTypes = {
    hasPhoto: PropTypes.bool,
    damageKey: PropTypes.string,
    isDuplicateDamage: PropTypes.bool,
    damage: PropTypes.object,
    photoIsRequired: PropTypes.bool
};

const defaultProps = {
    hasPhoto: false,
};


const invalidDamageKey = (damageKey) => {
    return !damageKey || damageKey.includes("null") || damageKey.charAt(damageKey.length - 1) === '-';
};

const TakePhotoRequired = (props) => {
    return (
        <>
            <TakePhotoButton hasPhoto={props.hasPhoto} damageKey={props.damageKey} disabled={props.disabled}
                             title={props.imageTitle} damageImage={props.damageImage}/>
            <RequiredPhotoMessage hasPhoto={props.hasPhoto}/>
        </>
    );
};

const TakePhotoNotRequired = (props) => {
    return (
        <>
            <TakePhotoButton hasPhoto={props.hasPhoto} damageKey={props.damageKey} disabled={props.disabled}
                             title={props.imageTitle} damageImage={props.damageImage}/>
            {props.hasPhoto && (
                <DeleteDamageImageButton
                    className="w-100 mt-3"
                    damageKey={props.damageKey}
                    disabled={props.disabled}
                />
            )}
        </>
    );
};

const TakePhoto = (props) => {
    const {hasPhoto, damageKey, damage, isDuplicateDamage, photoIsRequired} = props;
    const damageImage = props.damageImages.find(img => img.key === damageKey);

    return photoIsRequired ? (
        <TakePhotoRequired
            hasPhoto={hasPhoto}
            damageKey={damageKey}
            damageImage={damageImage}
            disabled={invalidDamageKey(damageKey) || isDuplicateDamage || !damage.item}
            imageTitle={damage.item}
        />
    ) : (
        <TakePhotoNotRequired
            hasPhoto={hasPhoto}
            damageKey={damageKey}
            damageImage={damageImage}
            disabled={invalidDamageKey(damageKey) || isDuplicateDamage || !damage.item}
            imageTitle={damage.item}
        />
    );
};

TakePhoto.propTypes = propTypes;
TakePhoto.defaultProps = defaultProps;

function mapStateToProps({ damageImages }) {
    return { damageImages };
}

export default connect(mapStateToProps, null)(TakePhoto);
