import React from 'react';
import Col from "@prism/col";
import RadioButton from "@prism/radiobutton";
import {addDamage, deleteAutoDamage, updateChargingCable} from "../../../actions/conditionActions";
import {addAnnouncementByCode, deleteAnnouncementByCode} from "../../../actions/announcementsActions";
import {CHARGING_CABLE_CATEGORIES} from "../../../utils/constants";
import {MISSING_CHARGING_CABLE_DAMAGE} from "../../../utils/constantsDamages";
import {connect} from "react-redux";
import Row from "@prism/row";
import AddDamageModal from "../condition/AddDamageModal";
import DataPair from "../../common/DataPair";
import {FormGroup} from "@prism/form";

class ChargingCable extends React.Component {

    state = {};

    handleChargingCableChange = (e) => {
        if (this.props.condition[e.currentTarget.name] === (e.currentTarget.value === "true")) {
            return
        }
        this.props.updateChargingCable(e.currentTarget.value === "true");
        if (e.currentTarget.value === "true") {
            const chargingCableDamage = this.props.automatedDamages.find(damage => damage.item === MISSING_CHARGING_CABLE_DAMAGE.item);
            if (chargingCableDamage) {
                this.props.deleteAutoDamage(chargingCableDamage);
            }
        } else {
            this.toggleDamageModal()
        }
    };

    handleCancelClick = () => {
        //forcing user to select Yes or No
        this.props.updateChargingCable(null);
        this.toggleDamageModal();
    };

    toggleDamageModal = () => {
        const {showDamageModal} = this.state;
        this.setState({
            showDamageModal: !showDamageModal,
        });
    };

    render() {
        const engine = this.props.designatedDescription?.powertrain?.engine;
        const isElectric = engine ? CHARGING_CABLE_CATEGORIES.includes(engine.fuelCategory) : false;
        const {chargingCable} = this.props.condition;

        if (isElectric) {
            return (
                <Col xs={12} sm={12} md={5} lg={5} xl={5} className="my-2 ml-3">
                    <AddDamageModal
                        showModal={this.state.showDamageModal}
                        onCancelClick={this.handleCancelClick}
                        toggle={this.toggleDamageModal}
                        damage={MISSING_CHARGING_CABLE_DAMAGE}
                    />
                    <Row className="align-items-center" id="charging-cable">
                        <DataPair label="Charging Cable Present?" required>
                            <FormGroup className="m-0" row>
                                <RadioButton
                                    className=""
                                    label="Yes"
                                    name="chargingCable"
                                    value={true}
                                    checked={chargingCable === true}
                                    onClick={this.handleChargingCableChange}
                                />
                                <RadioButton
                                    className="ml-3"
                                    label="No"
                                    name="chargingCable"
                                    value={false}
                                    checked={chargingCable === false}
                                    onClick={this.handleChargingCableChange}
                                />
                            </FormGroup>
                        </DataPair>
                    </Row>
                </Col>
            )
        } else {
            return null
        }
    }
}

const matchDispatchToProps = {
    updateChargingCable,
    addDamage,
    addAnnouncementByCode,
    deleteAnnouncementByCode,
    deleteAutoDamage
};


function mapStateToProps({condition, designatedDescription, automatedDamages}) {
    return {condition, designatedDescription, automatedDamages};
}

export default connect(mapStateToProps, matchDispatchToProps)(ChargingCable);